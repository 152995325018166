import { Box, Grid, Image } from "@chakra-ui/react";

export default function StaggeredColumnLayout() {


    return (
        <Grid templateColumns={["1fr", "1fr", "repeat(4, 1fr)"]} gap={10} mt={10}>
            <Box pt={[0, 0, 20]}>
                <Box height={'150px'} bg={'yellow.400'} borderRadius={25} display={['none', 'none', 'block']} />
                <Image src="/images/landing_image_grid_1.webp" mt={10}
                    h={[400, 400, 440]}
                    w={'100%'}
                    objectFit={'cover'}
                    borderRadius={20} />
            </Box>
            <Box pt={[0, 0, 40]}>
                <Image src="/images/landing_image_grid_2.webp" w={'100%'} h={[400, 400, 440]} objectFit={'cover'}
                    borderRadius={20} />
                <Image src="/images/landing_image_grid_3.webp" mt={10} w={'100%'} h={[400, 400, 345]} objectFit={'cover'}
                    borderRadius={20} />
            </Box>
            <Box>
                <Image src="/images/landing_image_grid_4.webp" w={'100%'} h={[400, 400, 495]} objectFit={'cover'}
                    borderRadius={20} />
                <Image src="/images/landing_image_grid_5.webp" mt={10} w={'100%'} h={[400, 400, 395]} objectFit={'cover'}
                    borderRadius={20} />
            </Box>
            <Box pt={[0, 0, 56]}>
                <Image src="/images/landing_image_grid_6.webp" w={'100%'} h={[400, 400, 535]} objectFit={'cover'}
                    borderRadius={20} />
                <Box height={'100px'} bg={'yellow.400'} borderRadius={25} mt={10} display={['none', 'none', 'block']} />
            </Box>
        </Grid>
    )
};
