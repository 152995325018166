import { Box,  Flex, Image } from "@chakra-ui/react";
import SocialMediaLinks from "./components/SocialMediaLinks";

export default function Header() {
    return (
        <header>
            <Flex justifyContent={'space-between'} px={[4, 4, 10]}>
                {/* Your header content goes here */}
                <Box p={4}>
                    <Image src={'/logo.png'} height={['45px', '45px', '60px']} w={[100, 100, 160]} />
                </Box>
                <Flex alignItems="center">
                    <SocialMediaLinks />
                </Flex>
            </Flex>
        </header>
    );
}
