import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Container, Flex, Grid, Text } from "@chakra-ui/react";

export default function FAQ() {
    return <Container maxW={'90%'} py={[10, 10, 150]} >
        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} // On mobile, each feature takes full width, on large screens, it's 2 columns
            gap={10} w={'100%'}>
            <Text as={'h3'} fontSize={[28, 28, 66]} fontWeight={300}>
                <Text fontWeight={600}>
                    Frequently Asked
                </Text>
                Questions
            </Text>
            <Flex alignItems={'center'}>
                <Accordion color={'green.900'} allowToggle w={'100%'}>
                    <AccordionItem bg={'white'} borderRadius={8}>
                        <h2>
                            <AccordionButton py={5}>
                                <Text as='span' flex='1' textAlign='left' fontWeight={600}>
                                    Can people who buy lands on LandDAO really move to live on the lands?
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            Yes, this is basically the philosophical premise of LandDAO. We want to build communities of like minded people that have shared common interests but come from all around the world. Each LandDAO community will have people from all over the world.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem mt={5} bg={'white'} borderRadius={8}>
                        <h2>
                            <AccordionButton py={5}>
                                <Text as='span' flex='1' textAlign='left' fontWeight={600}>
                                    Are people from every country allowed to buy land on LandDAO?
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            No. Residents and citizens of certain countries are not permitted to use LandDAO; USA, North Korea, Iran and Somalia.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Grid>
    </Container>
}