import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import Footer from './footer';

const LandingLayout = ({ children }) => {
    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">

            <main style={{ flex: 1 }}>
                {/* Render the children components */}
                {children}
            </main>
            <Divider borderColor={'rgba(221, 235, 236, 0.1)'} borderBottomWidth={0.1}/>
            <Footer />
        </Box>
    );
};

export default LandingLayout;
