import axios from 'axios'
import { BACKEND_DOMAIN } from 'utils/constants'

const instance = axios.create({
    baseURL: `${BACKEND_DOMAIN}/api`
})

export default {
    whitelist: (params) => instance.post('/whitelist/', params),
    contact: (params) => instance.post('/contact/', params),
    files: () => instance.get('/files/'),
}