export const inputStyles = {
  components: {
    Input: {
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: "8px",
        },
      },

      variants: {
        main: (props) => ({
            field: {
                border: "1px solid",
                color: "green.500",
                bg: 'transparent',
                borderColor: 'rgba(255, 255, 255, 0.3)',
                _placeholder: { color: "green.500" },
          },
        }),
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: (props) => ({
          field: {
            border: "1px solid",
                color: "green.500",
                bg: 'transparent',
                borderColor: 'rgba(255, 255, 255, 0.3)',
                _placeholder: { color: "green.500" },
          }
        })
      },
    },
  },
};
