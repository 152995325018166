import { Flex, Grid, Text, Image, Container } from "@chakra-ui/react";

const KeyFeatures = () => {
  return (
    <Flex
      bg={"green.900"}
      w={"100%"}
      pt={[100, 100, 500]}
      pb={20}
      mt={[-100, -100, -500]}
      flexDirection={'column'}
      alignItems={"center"}
      color={"white"}
    >
      <Text as={'h2'} fontSize={[28, 28, 66]} fontWeight={300} mt={[10, 10, 20]}>
        <Text as="span" fontWeight={600}>
          Key
        </Text>{" "}
        Features
      </Text>
      <Container maxW={'90%'}>
        <Grid
          mt={[10, 10, 20]}
          templateColumns={["1fr", "1fr", "repeat(4, 1fr)"]} // On mobile, each feature takes full width, on large screens, it's 2 columns
          gap={4} // Adjust the gap between items as needed
          justifyContent={"center"} // Center align items in grid
          w={'100%'}
        >
          {/* Each key feature */}
          <Flex textAlign="center" bgImage={'/images/landing_features_bg.webp'} bgSize={'100% 100%'} bgRepeat={'no-repeat'} p={10} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
            <Image src="/images/landing_features_1.webp" alt="Feature 1" h={100} w={100} />
            <Text mt={4}>
              Tokenizing Land on the<br /> Blockchain
            </Text>
          </Flex>
          <Flex textAlign="center" bgImage={'/images/landing_features_bg.webp'} bgSize={'100% 100%'} bgRepeat={'no-repeat'} p={10} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
            <Image src="/images/landing_features_2.webp" alt="Feature 1" h={100} w={100} />
            <Text mt={4}>
              Residential<br />Lands
            </Text>
          </Flex>
          <Flex textAlign="center" bgImage={'/images/landing_features_bg.webp'} bgSize={'100% 100%'} bgRepeat={'no-repeat'} p={10} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
            <Image src="/images/landing_features_3.webp" alt="Feature 1" h={100} w={100} />
            <Text mt={4}>
              Agricultural<br /> Lands
            </Text>
          </Flex>
          <Flex textAlign="center" bgImage={'/images/landing_features_bg.webp'} bgSize={'100% 100%'} bgRepeat={'no-repeat'} p={10} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
            <Image src="/images/landing_features_4.webp" alt="Feature 1" h={100} w={100} />
            <Text mt={4}>
              Community-Driven<br /> Governance
            </Text>
          </Flex>
        </Grid>
      </Container>
    </Flex>
  );
};

export default KeyFeatures;
