import { Flex, IconButton, Link, Text } from "@chakra-ui/react";
import { FaDiscord, FaMediumM, FaTwitter, FaYoutube } from "react-icons/fa";

export default function
    SocialMediaLinks() {
    return <Flex alignItems={'center'}>
        <Text as={Link} color={'white'} target="_blank" href="https://medium.com/@landdao_land" mr={3}>
            Blog
        </Text>
        {/* <IconButton
            aria-label="Discord"
            icon={<FaDiscord />}
            borderRadius="full"
            bg="transparent"
            border="1px solid white"
            color="white"
            _hover={{ bg: 'gray.600' }}
            as={Link}
            target="_blank"
            href="https://discord.gg/4MntspzqcM"
            size={['md']}
        /> */}
        <IconButton
            aria-label="Twitter"
            icon={<FaTwitter />}
            borderRadius="full"
            bg="transparent"
            border="1px solid white"
            color="white"
            _hover={{ bg: 'twitter.600' }}
            ml={2}
            as={Link}
            target="_blank"
            href="https://twitter.com/landdao_land"
            size={['md']}
        />

        <IconButton
            aria-label="Medium"
            icon={<FaMediumM />}
            borderRadius="full"
            bg="transparent"
            border="1px solid white"
            color="white"
            _hover={{ bg: 'twitter.600' }}
            ml={2}
            as={Link}
            target="_blank"
            href="https://medium.com/@landdao_land"
            size={['md']}
        />


        {/* <IconButton
            aria-label="Youtube"
            icon={<FaYoutube />}
            borderRadius="full"
            bg="transparent"
            border="1px solid white"
            color="white"
            _hover={{ bg: 'twitter.600' }}
            ml={2}
            as={Link}
            target="_blank"
            href="https://www.youtube.com/@landdao_land"
            size={['md']}
        /> */}
    </Flex>
}