import { Box, Button, Center, Flex, Grid, Icon, Image, Link, Text } from '@chakra-ui/react';
import LandingLayout from "layout/landing";
import Header from 'layout/landing/header';
import KeyFeatures from './components/KeyFeatures';
import { FaDiscord, FaRegCheckCircle } from 'react-icons/fa';
import StaggeredColumnLayout from './components/ImageGrid';
import FAQ from './components/FAQ';
import Form from './components/Form';
import JoinNowForm from './components/JoinNowForm';
import ExecutiveSummay from './components/ExecutiveSummary';
import VideoIframe from 'components/VideoIframe';

export default function Home() {

    return (
        <LandingLayout>
            <Box bgImage={'/images/landing_background_2.webp'} w={'100%'}
                backgroundSize="cover"
                backgroundPosition="center"
                position={'relative'}
                bgRepeat={'no-repeat'}
                height={[830, 830, 980]}
                zIndex={999}>
                <Header />
                <Flex w={'100%'} height={'100%'} justifyContent={'center'} alignItems={['flex-start', 'flex-start', 'flex-end']}>
                    <Flex bgImage={'/images/landing_top_transparent_bg.webp'}
                        backgroundSize="contain"
                        backgroundPosition="bottom"
                        bgRepeat={'no-repeat'}
                        height={[625, 625, '100%']}
                        w={[473, 773, 830]}
                        mb={[10, 10, 200]}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        <Text as={'h1'} color={'gold.400'} fontSize={[38, 38, 55]} textAlign={'center'} mx={15} fontWeight={700}>
                            Unlocking the World: <br />
                            LandDAO Your Gateway
                            <Text fontWeight={100} as="span"> to </Text>
                            <Text fontWeight={100}>Tokenized Land<br />
                                Ownership
                            </Text>
                        </Text>
                        <JoinNowForm />
                    </Flex>
                </Flex>
            </Box>


            <Flex bgImage={'/images/landing_background_3.webp'} w={'100%'}
                backgroundSize="cover"
                bgRepeat={'no-repeat'}
                backgroundPosition="center"
                h={[1200, 1200, 1800]}
                mt={[-100, -100, -300]}
                pt={[120, 120, 300]}
                alignItems={'center'}
                flexDirection={'column'}
                position={'relative'}
                zIndex={998}>
                <Text as={'h2'} fontSize={[28, 28, 66]} bgClip="text" bgGradient="linear-gradient(90deg, #012E33 0%, rgba(1, 46, 51, 0.5) 100%)">
                    <Text fontWeight={600} as="span">What is&nbsp;</Text>
                    <Text fontWeight={100} as={'span'}>
                        LandDAO?
                    </Text>
                </Text>
                <Box w={[400, 400, 1000]}>
                    <VideoIframe videoId="VnT4XSsc1lM" autoPlay={true}/>
                </Box>
                <Box display={['none', 'none', 'block']} color={'green.900'}>
                    <Flex alignItems={'flex-end'} mt={10} >
                        <Text textAlign={'right'}>Lands traded on LandDAO <br /> are real lands and NOT<br /> virtual lands.</Text>
                        <Image src="/images/landing_what_is_landdao.webp" height={520} w={580} />
                        <Text>We are building a global land<br /> portfolio and tokenizing it as a <br />real world asset (RWA).</Text>
                    </Flex>
                    <Text textAlign={'center'}>We make land tradeable as a<br /> tokenized RWA (Real World Asset).</Text>
                </Box>
                <Flex display={['block', 'block', 'none']} mt={10} position={'relative'}>
                    <Image src="/images/landing_what_is_landdao_m.webp" />
                    <Flex position={'absolute'} top={0} left={0} right={0} bottom={0} flexDirection={'column'} color={'green.900'} >
                        <Text alignSelf={'center'} textAlign={'center'} mt={340}>
                            We are building a global land<br /> portfolio and tokenizing it as a real<br /> world asset (RWA).
                        </Text>
                        <Text alignSelf={'flex-start'} textAlign={'left'} mt={'50px'}>
                            Lands traded on LandDAO are real<br /> lands and NOT virtual lands.
                        </Text>
                        <Text alignSelf={'flex-end'} textAlign={'right'} mt={'50px'}>
                            We make land tradeable as a<br /> tokenized RWA (Real World Asset).
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <KeyFeatures />

            <Box p={[10, 10, 100]} >
                <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} // On mobile, each feature takes full width, on large screens, it's 2 columns
                    gap={10} w={'100%'}>
                    <Flex flexDir={'column'} height={'100%'} justifyContent={'center'} order={[1, 1, 2]}>
                        <Text as={'h2'} fontSize={[28, 28, 66]} fontWeight={300} mt={[10, 10, 20]}>
                            <Text as="span" fontWeight={600}>
                                Tokenizing
                            </Text>{" "}
                            Land
                        </Text>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>LandDAO acquires large tracts of land.</Text>
                        </Flex>
                        <Flex mt={2}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Significant enhancements are made to the land.</Text>
                        </Flex>
                        <Flex mt={2}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Lands are tokenized into digital assets.</Text>
                        </Flex>
                        <Flex mt={2}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Ownership is transferred through smart contracts.</Text>
                        </Flex>
                    </Flex>
                </Grid>
            </Box>

            <Flex bg={'green.300'} flexDir={'column'} alignItems={'center'} color={'green.900'} p={[10, 10, 20]}>
                <Text as={'h2'} fontSize={[28, 28, 66]} fontWeight={300} >
                    <Text as="span" fontWeight={600}>
                        Global
                    </Text>{" "}
                    Focus
                </Text>
                <Text textAlign={'center'}>
                    We are initially operating in locations below.<br /> We operate in countries where non-citizens can own land without restrictions.
                </Text>
                <Image src='/images/landing_global.webp' mt={[5, 5, 20]} h={[90, 90, 500]} />
            </Flex>

            <Flex bg={'yellow.200'} flexDir={'column'} alignItems={'center'} color={'green.900'} p={[10, 10, 20]}>
                <Text fontSize={[28, 28, 66]} fontWeight={300} >
                    <Text as="span" fontWeight={600}>
                        Featured
                    </Text>{" "}
                    Land
                </Text>
                <Text textAlign={'center'}>
                    Kwahu Mountains, Ghana
                </Text>
                <StaggeredColumnLayout />
            </Flex>

            <Box color={'white'} bg={'green.900'} position={'relative'} height={[630, 630, 740]}>
                <Image position={'absolute'} src='/images/landing_background_5.webp' bottom={0} top={[null, null, 0]} />
                <Flex p={[10, 10, 100]} height={'100%'} justifyContent={'end'} alignItems={['start', 'start', 'center']} zIndex={999} position={'relative'}>
                    <Flex flexDir={'column'} justifyContent={'center'} order={[1, 1, 2]} >
                        <Text as={'h2'} fontSize={[28, 28, 66]} fontWeight={300} mt={[10, 10, 20]}>
                            <Text as="span" fontWeight={600}>
                                Why Join
                            </Text>{" "}
                            LandDAO
                        </Text>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Invest in real-world land assets</Text>
                        </Flex>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Transparent, secure blockchain transactions.</Text>
                        </Flex>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Take physical possession of a land if you want.</Text>
                        </Flex>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Profits are distributed to DAO members quarterly.</Text>
                        </Flex>
                        <Flex mt={5}>
                            <Icon as={FaRegCheckCircle} mt={2} />
                            <Text ml={2}>Buy and hold lands to profit from future price growth.</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>

            <FAQ />
            <Flex color={'green.900'} bg={'gray.200'} flexDir={'column'} alignItems={'center'} p={[10, 10, 20]}>
                <Text as={'h2'} fontSize={[28, 28, 66]} fontWeight={300} >
                    <Text as="span" fontWeight={600}>
                        Social
                    </Text>{" "}
                    Impact
                </Text>
                <Grid templateColumns={["1fr", "1fr", "repeat(3, 1fr)"]} // On mobile, each feature takes full width, on large screens, it's 2 columns
                    gap={10} w={'100%'} mt={16}>
                    <Flex flexDir={'column'} justifyContent={'space-around'} >
                        <Text textAlign={[null, null, 'right']}>LandDAO brings huge economic<br /> investments and development.</Text>
                        <Text pr={[0, 0, 36]} mt={[5, 5, null]}>A percentage of LandDAO's profits is<br /> distributed back to local communities.</Text>
                        <Text textAlign={[null, null, 'right']} mt={[5, 5, null]}>LandDAO works to provide skills<br /> training in local communities.</Text>
                    </Flex>
                    <Flex justifyContent={'center'}>
                        <Image src="/images/landing_image_5.webp" mt={5} h={[264, 264, 392]} w={[264, 264, 392]} />
                    </Flex>
                    <Flex flexDir={'column'} justifyContent={'space-around'} >
                        <Text textAlign={[null, null, 'left']}>LandDAO prioritizes hiring from<br /> local communities for all jobs.</Text>
                        <Text pl={[0, 0, 36]} mt={[5, 5, null]}>Local communities are granted <br /> access to amenities.</Text>
                        <Text textAlign={[null, null, 'left']} mt={[5, 5, null]}>LandDAO invests in modernizing and<br /> expanding social infrastructure.</Text>
                    </Flex>

                </Grid>
            </Flex>


            <ExecutiveSummay />
            <Flex color={'green.900'} bg={'gray.200'} flexDir={'column'} alignItems={'center'} p={[10, 10, 20]}>
                <Icon as={FaDiscord} mt={5} fontSize={[28, 28, 66]} />
                <Text textAlign={'center'} mt={10}>LandDAO uses Discord as chat channel. <br /> To get in touch with the LandDAO project team members, join our Discord channel</Text>
                <Button as={Link} bg={'green.900'}
                    color='white'
                    _hover={{ bg: 'green.400' }}
                    target='_blank' mt={10} href='https://discord.gg/4MntspzqcM'>JOIN DISCORD !</Button>
            </Flex>
            <Form />
        </LandingLayout>
    );
}
